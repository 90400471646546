import { CommonModule } from '@angular/common';
import { NgModule, Type } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbDropdownModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SyngCommonModule, DynamicFormModule, TranslationsModule, ValkyrieUiModule } from '@symplicity/syng';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

/** Components */
import { DropdownFilterComponent } from './components/list-dynamic-form-filter/dropdown-filter.component';
import { GroupFiltersComponent } from './components/list-dynamic-form-filter/group-filters.component';
import { ListDynamicFormFilterFieldsComponent } from './components/list-dynamic-form-filter/list-dynamic-form-filter-fields.component';
import { ListDynamicFormFilterComponent } from './components/list-dynamic-form-filter/list-dynamic-form-filter.component';
import { NgxModalComponent } from './components/ngx-modal.component';
import { WhenAndWhereComponent } from './components/when-and-where.component';
import { HeroBannerComponent } from './components/hero-banner.component';
import { HomeTilesComponent } from './components/home-tiles.component';
import { BackToTopComponent } from './components/back-to-top.component';
import { AnnouncementModal } from './components/announcement-modal.component';
import { AnnouncementsList } from './components/announcements-list.component';

/** Directives */
import { FlickityDirective } from './directives/flickity.directive';
import { IntersectionObserverDirective } from './directives/intersectionDirective';

/** Pages */
import { ErrorPageComponent } from './pages/error-page.component';

/** Pipes */
import { LinkyPipe } from './pipes/linky.pipe';

const COMPONENTS: Type<any>[] = [
    DropdownFilterComponent,
    GroupFiltersComponent,
    ListDynamicFormFilterFieldsComponent,
    ListDynamicFormFilterComponent,
    NgxModalComponent,
    WhenAndWhereComponent,
    HeroBannerComponent,
    HomeTilesComponent,
    BackToTopComponent,
    AnnouncementModal,
    AnnouncementsList,
];

const DIRECTIVES: Type<any>[] = [
    FlickityDirective,
    IntersectionObserverDirective
];

const PIPES: Type<any>[] = [
    LinkyPipe
]

const PAGES: Type<any>[] = [
    ErrorPageComponent
];

@NgModule({
    declarations: [
        ...COMPONENTS,
        ...DIRECTIVES,
        ...PAGES
    ],
    imports: [
        CommonModule,
        RouterModule,
        TranslationsModule.forChild(),
        TooltipModule,
        DynamicFormModule,
        NgbModule,
        NgbDropdownModule,
        BsDropdownModule.forRoot(),
        ValkyrieUiModule,
        SyngCommonModule,
        ...PIPES
    ],
    exports: [
        ...COMPONENTS,
        ...DIRECTIVES,
        ...PAGES,
        ...PIPES
    ]
})
export class CSMSharedModule { }

/** Exports */
export * from './components/list-dynamic-form-filter/dropdown-filter.component';
export * from './components/list-dynamic-form-filter/group-filters.component';
export * from './components/list-dynamic-form-filter/list-dynamic-form-filter-fields.component';
export * from './components/list-dynamic-form-filter/list-dynamic-form-filter.component';
export * from './components/ngx-modal.component';
export * from './components/when-and-where.component';
export * from './components/hero-banner.component';
export * from './components/back-to-top.component';
export * from './components/when-and-where.model';
export * from './components/announcement-modal.component';
export * from './components/announcements-list.component';
export * from './directives/flickity.directive'
export * from './directives/intersectionDirective'
export * from './pages/error-page.component';
export * from './services/event-date.service';
export * from './services/system-settings.service';
export * from './services/form-config.service';
export * from './services/list-dynamic-form-filter.service';
export * from './services/list-utilities.service';
export * from './services/text-editor.service';
export * from './pipes/linky.pipe';
export * from './services/session-storage.service';
