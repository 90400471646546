import { Injectable } from "@angular/core";

@Injectable({ providedIn: 'root' })

export class ListUtilitiesService {

    constructor () {}

    getEmailCategoriesForJanusBatchAction(messages: any) {
        const transformedData = [];
        const categoryMap = {};

        messages.forEach(item => {
            const newItem = {
                ...item,
                id: item.message_id,
                display: item.subject,
                value: item.message_id,
                group: 'mail',
                children: []
            };

            if (item.category) {
                const categoryId = item.category.replace(/\s+/g, '_');
                if (!categoryMap[categoryId]) {
                    categoryMap[categoryId] = {
                        id: categoryId,
                        display: item.category,
                        group: 'mail',
                        children: []
                    };
                }
                categoryMap[categoryId].children.push(newItem);
            } else {
                transformedData.push(newItem);
            }
        });
        const categoryItems = Object.values(categoryMap);
        const finalArray = [
            ...transformedData.filter(item => item.id === "__new"),
            ...categoryItems,
            ...transformedData.filter(item => item.id !== "__new")
        ];
        return finalArray;
    }
}
