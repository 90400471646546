import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ValkyrieBackendService } from "@symplicity/syng";
import { fromEvent, Subscription } from "rxjs";
import { filter } from "rxjs/operators";

declare var window: Window;

@Component({
    selector: 'hero-banner',
    templateUrl: 'hero-banner.component.html'
})
export class HeroBannerComponent {
    @ViewChild("bannerFlickity", {static: false,}) bannerFlickityEl: ElementRef<HTMLDivElement>;
    @ViewChild("liveRegion", {static: false}) liveRegionEl: ElementRef<HTMLSpanElement>;

    bannerFlickity: any;
    bannerSubscription: Subscription;
    bannerFocusSubscription: Subscription;
    bannerBlurSubscription: Subscription;
    bannerData: any;

    @Input() pauseButton = false;

    @Output() onOpenModal = new EventEmitter<any>();
    @Output() emptyModels = new EventEmitter<void>();

   bannerFlickityOptions = {
        "wrapAround": true,
        "autoPlay": 5000,
        "rightToLeft": window.document.dir === 'rtl',
        "accessibility": false,
        "imagesLoaded": true,
        "prevNextButtons": true,
        "pageDots": true
    }
    bannerFlickityPaused = false;

    KEYS = {
        ENTER: 13,
        SPACE: 32,
        LEFT:  37,
        UP:    38,
        RIGHT: 39,
        DOWN:  40
    };

    constructor (
        private backendService: ValkyrieBackendService,
        private translate: TranslateService,
    ) {
        this.intersectionCallback = this.intersectionCallback.bind(this);
    }

    ngOnInit() {
        this.backendService.getAll('announcements', 'v2',{ listdef: 'HeroBannerAnnouncementList', perPage:'5' })
            .subscribe( res => {
                if (!res.models.length) {
                    this.bannerData = res;
                    this.emptyModels.emit();
                    return;
                }
                if (res.models.length === 1) {
                    this.bannerFlickityOptions.pageDots = false;
                    this.bannerFlickityOptions.prevNextButtons = false;
                    this.pauseButton = false;
                }
                res.models = res.models.map(model => {
                    model.use_background_overlay = false;

                    if (model.background_image) {
                        const background_position = model.background_image_alignment ? model.background_image_alignment.replace("_", " ") : "center center";
                        model.extra_styles = {"background-image": "url(" + model.background_image + ")", "background-position": background_position};
                        model.use_background_overlay = true;
                    }
                    if (model.custom_link_override) {
                        model.target = "_self";
                        const url = new URL(model.custom_link_override);
                        if (url.protocol === "http:") {
                            model.custom_link_override = model.custom_link_override.replace("http:", "https:")
                        }
                        if (url.host !== window.location.host) {
                            model.target = "_blank"
                        }
                    }
                    return model;
                })
                this.bannerData = res;
            });
        }

    ngOnDestroy() {
        if(this.bannerSubscription) { this.bannerSubscription.unsubscribe(); }
        if(this.bannerBlurSubscription) { this.bannerBlurSubscription.unsubscribe(); }
        if(this.bannerFocusSubscription) { this.bannerFocusSubscription.unsubscribe(); }
    }

    onStaticClick({ event, cellElement, cellIndex }) {
        this.openModal(cellIndex)
    }

    bannerReady(flickity) {
        this.bannerFlickity = flickity;
        this.bannerSubscription = this.flickityKeyboardListener(this.bannerFlickityEl.nativeElement, this.bannerFlickity);
        this.bannerBlurSubscription = fromEvent(this.bannerFlickityEl.nativeElement, 'focusout').subscribe(() => {
            !this.bannerFlickityPaused && this.bannerFlickity.playPlayer();
        })
        this.bannerFocusSubscription = fromEvent(this.bannerFlickityEl.nativeElement, 'focusin').subscribe(() => {
            this.bannerFlickity.stopPlayer();
        })
    }

    intersectionCallback(entries: IntersectionObserverEntry[]) {
        if (!this.bannerFlickity) { return }
        if (!entries[0].isIntersecting) {
            this.bannerFlickity.stopPlayer();
        } else {
            if (!document.activeElement.closest('.hero-announcement-carousel-wrapper')) {
                this.bannerFlickity.playPlayer();
            }
        }
    }

    openModal(cellIndex: number) {
        if (this.bannerData.models[cellIndex].custom_link_override) return; // If item has custom_link_override, don't show modal.
        this.onOpenModal.emit(this.bannerData.models[cellIndex].announcement_id);
        this.bannerFlickity.stopPlayer();
    }

    onPause() {
        if (this.bannerFlickityPaused) {
            this.bannerFlickity.playPlayer();
        } else {
            this.bannerFlickity.stopPlayer();
        }

        this.bannerFlickityPaused = !this.bannerFlickityPaused
    }

    flickityKeyboardListener(flickityElement, flickity) {
        return fromEvent<KeyboardEvent>(flickityElement, 'keyup').pipe(
            filter(e => (!!Object.keys(this.KEYS).find(key => this.KEYS[key] == e.keyCode)) && (e.target as HTMLElement).tagName !== "BUTTON")
            ).subscribe(e => {
                switch (e.keyCode) {
                    case this.KEYS.LEFT:
                    case this.KEYS.UP: {
                        this.handleAxFlow(flickity, false);
                        break;
                    }
                    case this.KEYS.RIGHT:
                    case this.KEYS.DOWN: {
                        this.handleAxFlow(flickity, true);
                        break;
                    }
                    case this.KEYS.SPACE:
                    case this.KEYS.ENTER: {
                        this.openModal(flickity.selectedIndex)
                        break;
                    }
                }
            }
            )
        }

        handleAxFlow(flickity: any, next: boolean) {
            if (next) {
                flickity.next(true, false); // 'next' and 'previous' params:  isWrapped: wrap around the elements | isInstant: without animation.
            } else {
                flickity.previous(true, false);
            }

            flickity.selectedElement.focus();
            this.liveRegionEl.nativeElement.textContent = this.translate.instant(
                'homepage hero_banner.announcement x of y', {position: flickity.selectedIndex + 1, total: flickity.slides.length}
            );
        }
}
