import { ViewChild, Component, Input, TemplateRef, ViewContainerRef, Inject, Optional } from '@angular/core';
import { BsModalRef } from "ngx-bootstrap/modal";
import { DomSanitizer } from '@angular/platform-browser';
import { Subject } from 'rxjs';

@Component({
    selector: 'ngx-modal',
    templateUrl: './ngx-modal.component.html'
})

export class NgxModalComponent {

    @ViewChild('templateRef', {read: ViewContainerRef}) vc: ViewContainerRef;

    @Input() alertHeaderTitle;
	@Input() alertMessage;
	@Input() alertButtonName;
    @Input() alertButtonClass;
    @Input() alertCancelButtonName;
    @Input() alertAllowClose = true;
    @Input() isAlertMessageTranslated = false;
    @Input() hasFooter = true;
    @Input() whiteHeader = false;
    @Input() whiteFooter = false;

    private htmlContent;
    public useTemplateRef: boolean = false;
    public alertTitle: '';
    public onClose: Subject<boolean>;

    constructor(
        public bsModalRef: BsModalRef,
		private sanitizer: DomSanitizer,
        private ViewContainerRef: ViewContainerRef,
        @Optional() @Inject('ENABLE_JANUS') public janus: number,
    ) {
    }

    ngOnInit(): void {
        if (this.isAlertMessageTranslated) {
            this.htmlContent = this.sanitizer.bypassSecurityTrustHtml(this.alertMessage);
        }
    }

    public onConfirm(): void {
        this.onClose?.next(true);
        this.bsModalRef.hide();
    }

    public onCancel(): void {
        this.onClose?.next(false);
        this.bsModalRef.hide();
    }

    ngAfterViewInit() {
        setTimeout(() => {
            if (this.alertMessage instanceof TemplateRef) {
                this.useTemplateRef = true;
                const view = this.ViewContainerRef.createEmbeddedView(this.alertMessage);
                this.vc.insert(view);
            }
        });
    }
}
